import React from "react";
import styled from "styled-components";
import "./approachExt.css";

const ApproachExt = (props) => {
  return (
    <Wrapper>
      <div
        id="approachExt"
        style={{ marginTop: "20vh", padding: "5vh" }}
        className="pad"
      >
        <div className="container-fluid">
          {/* Use container-fluid for full width */}
          <div className="row">
            <div className="col-12">
              <h2
                className="header"
                style={{ fontSize: "2.8em", marginBottom: "7vh" }}
              >
                Transform your data with
                <span className="themed_color"> GenAI </span>powered insights
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-24">
              <ImageContainer>
                <img
                  src="/assets/images/approach_logo.png"
                  alt="Approach Logo"
                  style={{ width: "70%" }}
                  className="log"
                />
              </ImageContainer>
            </div>
            <div className="col-lg-5 col-md-12" style={{}}>
              <TextContainer style={{ height: "93%", width: "80%" }}>
                <EqualHeightColumns>
                  <div className="col-lg-12 left" style={{ marginTop: "-4vh" }}>
                    <span
                      style={{
                        fontSize: "2.4em",
                        margin: "0",
                        marginBottom: "0.8em",
                      }}
                    >
                      70%
                    </span>
                    <p
                      className="full-width"
                      style={{
                        fontSize: "1.3em",
                        margin: "0",
                        width: "100%",
                        color: "#969696",
                      }}
                    >
                      <span className="themed_color">Productivity Gains </span>{" "}
                      by reducing manhours required to perform data analysis
                    </p>
                  </div>
                  <div className="col-lg-12 left">
                    <span style={{ fontSize: "2.4em", margin: "0" }}>90%</span>
                    <p
                      className="full-width"
                      style={{
                        fontSize: "1.3em",
                        margin: "0",
                        width: "100%",
                        color: "#969696",
                      }}
                    >
                      <span className="themed_color">Acceleration </span> in
                      analytics use case implementation through natural language
                      interface
                    </p>
                  </div>
                  <div className="col-lg-12 left">
                    <span style={{ fontSize: "2.4em", margin: "0" }}>5x</span>
                    <p
                      className="full-width"
                      style={{
                        fontSize: "1.3em",
                        margin: "0",
                        width: "100%",
                        color: "#969696",
                      }}
                    >
                      <span className="themed_color">Increased Adoption </span>{" "}
                      by removing technical dependencies through automation
                    </p>
                  </div>
                </EqualHeightColumns>
              </TextContainer>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  padding: 0;
`;

const ImageContainer = styled.div`
  text-align: center;
  margin: 5px 0; /* Add margin top and bottom for smaller screens */
  @media (max-width: 922px) {
    width: "100% !important";
  }
`;

const TextContainer = styled.div`
  text-align: center; /* Center-align text on small screens */
  @media (min-width: 992px) {
    text-align: start;
  }

  display: flex;
  align-items: stretch;
  height: 100%;
  margin: 20px 0; /* Add margin top and bottom for smaller screens */
`;

const EqualHeightColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1; /* Use flex to distribute height equally */

  @media (max-width: 768px) {
    font-size: 0.5em; /* Adjust font size for smaller screens */
    margin: 50px 0;
  }
`;

export default ApproachExt;
