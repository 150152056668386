import React from "react";
// import Button from "../../utils/Button";
import styled from "styled-components";
import "./Welcome.css";

const StyledH1 = styled.h1`
  font-size: 3.1em;

  @media (max-width: 1280px) {
    font-size: 2em;
  }

  @media (max-width: 576px) {
    font-size: 1.5em;
  }
`;

const WelcomePage = () => {
  return (
    <div className="container-fluid" style={styles.wrapperMain}>
      <div
        className="row small-pad"
        style={{ paddingLeft: "5vh", paddingRight: "7vh", marginTop: "4vh" }}
      >
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div
            className="panel"
            style={{ ...styles.wrapper, ...styles.textWrapper }}
          >
            <StyledH1 className="bold">
              Empowering Business Users With Self Serve Analytics
            </StyledH1>
            <h3
              className="bold through"
              style={{ fontSize: "1.2em", marginTop: "-4vh" }}
            >
              Through Seamless Fusion of{" "}
              <span className="themed_color">Business</span> &{" "}
              <span className="themed_color">Technology</span>
            </h3>
            <p>
              <span className="themed_color bold">GenAI</span> Powered analytics
              platform that automatically converts business requirements into
              executable machine language, enabling users with explainable &
              accurate insights at unparalleled speed & scale
            </p>
            <div>
              <a
                style={{
                  background: "#000",
                  color: "#ccc",
                  fontSize: "1.4em",
                  margin: "0px",
                  padding: "10px 20px",
                  textDecoration: "none",
                  display: "inline-block",
                  borderRadius: "8px",
                  cursor: "pointer",
                  textAlign: "center",
                  transition: "background-color 0.2s, color 0.2s",
                }}
                href="https://calendly.com/prinkan-pal/30min"
                target={"new"}
                onMouseEnter={(e) => {
                  e.target.style.background = "#222";
                  e.target.style.color = "#fff";
                }}
                onMouseLeave={(e) => {
                  e.target.style.background = "#000";
                  e.target.style.color = "#ccc";
                }}
              >
                {"Book Demo"}
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="panel2" style={styles.ultraWrapper}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/legoai-ontolite-process.gif"
              }
              alt="Your Alt Text"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapperMain: {
    padding: "7vh",
  },
  wrapper: {
    minHeight: "90vh",
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    // justifyContent: "space-evenly",
    alignItems: "space-evenly",
    // paddingTop: "5%",
    paddingBottom: "5%",
  },
  textWrapper: {
    display: "flex",
  },
  ultraWrapper: {
    minHeight: "90vh",
    display: "flex",
    // justifyContent: "space-around",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
};

// Media query for smaller screens
const smallerScreensMediaQuery = `@media (max-width: 576px) {
  .ultraWrapper {
    min-height: 50vh; // Adjust this value as needed
  }
}`;

export default WelcomePage;
