import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import announcements from "./announcementConfig";

const AnnouncementBar = () => {
  const [fontSize, setFontSize] = useState("12px"); // Default font size

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setFontSize("9px");
      } else {
        setFontSize("12px");
      }
    };

    // Initial font size setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ ...announcementStyles.container, fontSize }}>
      <Marquee gradient={false} speed={50}>
        {announcements.map((announcement, index) => (
          <div key={index} style={announcementStyles.announcement}>
            {announcement.link ? (
              <a
                href={announcement.link}
                target="_blank"
                rel="noopener noreferrer"
                style={announcementStyles.link}
              >
                {announcement.text}
              </a>
            ) : (
              <p2 style={announcementStyles.text}>{announcement.text}</p2>
            )}
          </div>
        ))}
      </Marquee>
    </div>
  );
};

const announcementStyles = {
  container: {
    background: "#1b1b1b",
    color: "#fff",
    textAlign: "center",
    padding: "6px 0",
    position: "fixed",
    top: 0,
    // zIndex: 100000,
    width: "100%",
  },
  announcement: {
    textAlign: "center",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    cursor: "pointer",
  },
  text: {
    margin: 0,
  },
};

export default AnnouncementBar;
