import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./components/Contact2/Contact";

import SmoothScroll from "smooth-scroll";
// import Footer from "./components/home/Footer.js.del";
import Header from "./components/home/Header";
// import OntoQl from "./components/OntoQl/OntoQl";
// import OntoSphere from "./components/OntoSphere/OntoSphere";

import WelcomePage from "./components/home/WelcomePage";
// import Ontocraft from "./components/Ontocraft/Ontocraft";
// import Archi from "./components/Arcit/Archi";
// import VisionBlock from "./components/About/VisionBlock";
import NewTeamCard from "./components/About/NewTeamCard";
import ScrollToTop from "./components/home/ScrollToTop";
// import BannerVideo from "./components/home/BannerVideo";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Approach from "./components/approach/approach";
import ApproachExt from "./components/approach/approachExt";
import Pricing from "./components/Pricing/pricing";
import Platform from "./components/Platform/platform";
// import Feeds from "./components/feeds";
import MarqueeObj from "./components/Marquee/Marquee";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

export default function App(props) {
  const isConsent = getCookieConsentValue();
  //   const [isHidden, setIsHidden] = useState(false);

  return (
    <div id="mybody">
      <Header />

      <WelcomePage />
      <Platform />
      <Approach />
      <ApproachExt />
      <MarqueeObj />
      {/* <VisionBlock /> */}
      <NewTeamCard />
      <Pricing />

      <Contact />

      {isConsent ? null : (
        <CookieConsent
          location="bottom"
          buttonText="Got it!!"
          cookieName="LegoAi"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            color: "#4e503b",
            fontSize: "13px",
            minWidth: "300px",
            minHeight: "50px",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      )}

      <ToastContainer />

      <ScrollToTop />
    </div>
  );
}
