import React from "react";
import "./NewTeamCard.css";

import { AiOutlineLinkedin } from "react-icons/ai";

const NewTeamCard = (props) => {
  const desc_style = {
    fontSize: "17px",
    padding: "10px 10px 10px 20px",
    textAlign: "center",
    fontFamily: "Carlito",
    color: "#B9B9B9",
    margin: "0",
  };
  return (
    <div
      id="team"
      style={{ marginTop: "0vh", paddingTop: "17vh" }}
      className="team-top"
    >
      <div className="container-fluid">
        {/* Use container-fluid for full width */}

        <div className="row">
          <div className="col-12 text-center d-lg-none d-md-none top-pad">
            <h2
              className="header"
              style={{ fontSize: "3em", marginBottom: "5vh" }}
            >
              Founding Team
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div
              className="d-flex flex-column align-items-center "
              style={{ marginTop: "5vh" }}
            >
              <img
                src="/assets/images/prinkan.png"
                className="team-img"
                alt="pic"
                loading="lazy"
                style={{ width: "50%" }}
              />
              <div className="d-flex align-items-center">
                <h3
                  style={{
                    fontSize: "1.3em",
                    paddingTop: "1.2vh",
                    paddingRight: "1vh",
                  }}
                >
                  Prinkan Pal
                </h3>
                <a
                  href="https://www.linkedin.com/in/prinkan/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiOutlineLinkedin color="white" fontSize={28} />
                </a>
              </div>
              <h3 style={{ fontSize: "1em" }}>CEO</h3>
              <p style={desc_style}>
                Prinkan has led AI Engineering and Innovation for Analytics and
                Consulting companies. He has built teams and businesses around
                multiple AI products and solutions and helped global MNCs scale
                AI-led digital business models. He is passionate about applying
                technological innovations towards business outcomes.
              </p>
              <p
                className="reduce-space"
                style={{ margin: "0", textAlign: "center" }}
              >
                Business Leader, International Speaker.
              </p>
              <p className="reduce-space">​Ex – BRIDGEi2i, Accenture​</p>
            </div>
          </div>

          <div className="col-lg-9 ">
            <div className="divider" style={{ paddingLeft: "2vh" }}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="d-flex flex-column align-items-center top-pad">
                    <img
                      src="/assets/images/jaskaran.png"
                      className="team-img"
                      alt="pic"
                      loading="lazy"
                      style={{ width: "50%" }}
                    />
                    <div className="d-flex align-items-center">
                      <h3
                        style={{
                          fontSize: "1em",
                          textAlign: "center",
                          paddingTop: "1.2vh",
                          paddingRight: "1vh",
                        }}
                      >
                        Jaskaran Singh
                      </h3>
                      <a
                        href="https://www.linkedin.com/in/jaskaran-s-879192a1/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiOutlineLinkedin color="white" fontSize={28} />
                      </a>
                    </div>
                    <h3 style={{ fontSize: "0.8em", textAlign: "center" }}>
                      Chief Artificial Intelligence Officer
                    </h3>
                    <p
                      style={{
                        fontSize: "11px",
                        textAlign: "center",
                        fontFamily: "Carlito",
                        color: "#B9B9B9",
                        margin: "0",
                        padding: "1vh",
                        paddingRight: "3.5vh",
                        paddingLeft: "3.5vh",
                      }}
                    >
                      Jaskaran has built advanced AI Centre of Excellence for
                      Analytics and Mgmt. consulting companies. Led a community
                      of ~300 data scientists to deliver production grade AI
                      algorithms.
                    </p>
                    <div className="d-flex flex-column align-items-center">
                      <p
                        className="reduce-space"
                        style={{
                          marginTop: "-6px",
                          fontSize: "0.8em",
                          textAlign: "center",
                          marginBottom: "8px",
                        }}
                      >
                        Gen AI Researcher. Inventor.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="d-flex flex-column align-items-center top-pad">
                    <img
                      src="/assets/images/pradeep.png"
                      className="team-img"
                      alt="pic"
                      loading="lazy"
                      style={{ width: "50%" }}
                    />
                    <div className="d-flex align-items-center ">
                      <h3
                        style={{
                          fontSize: "1em",
                          textAlign: "center",
                          paddingTop: "1.4vh",
                          paddingRight: "1vh",
                        }}
                      >
                        Pradeep Patil
                      </h3>
                      <a
                        href="https://www.linkedin.com/in/pradeep-p-5301aa49/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiOutlineLinkedin color="white" fontSize={28} />
                      </a>
                    </div>
                    <h3 style={{ fontSize: "0.8em", textAlign: "center" }}>
                      Chief Technology Officer
                    </h3>
                    <p
                      style={{
                        fontSize: "11px",
                        textAlign: "center",
                        fontFamily: "Carlito",
                        color: "#B9B9B9",
                        margin: "0",
                        padding: "1vh",
                        paddingRight: "3.5vh",
                        paddingLeft: "3.5vh",
                      }}
                    >
                      Pradeep has been the Chief Architect of Data & AI
                      Solutions for multiple F500 companies. Led global teams of
                      Data and ML architects in building Enterprise AI
                      platforms.
                    </p>
                    <div className="d-flex flex-column align-items-center">
                      <p
                        className="reduce-space"
                        style={{
                          marginTop: "-6px",
                          fontSize: "0.8em",
                          textAlign: "center",
                          marginBottom: "8px",
                        }}
                      >
                        Tech Evangelist & Speaker.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="d-flex flex-column align-items-center top-pad">
                    <img
                      src="/assets/images/manan.png"
                      className="team-img"
                      alt="pic"
                      loading="lazy"
                      style={{ width: "48%" }}
                    />
                    <div className="d-flex align-items-center">
                      <h3
                        style={{
                          fontSize: "1em",
                          textAlign: "center",
                          paddingTop: "1.2vh",
                          paddingRight: "1vh",
                        }}
                      >
                        Manan Pachnanda
                      </h3>
                      <a
                        href="https://www.linkedin.com/in/manan-pachnanda-09a73356/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiOutlineLinkedin color="white" fontSize={28} />
                      </a>
                    </div>
                    <h3 style={{ fontSize: "0.8em", textAlign: "center" }}>
                      Chief Product Officer
                    </h3>
                    <p
                      style={{
                        fontSize: "11px",
                        textAlign: "center",
                        fontFamily: "Carlito",
                        color: "#B9B9B9",
                        margin: "0",
                        padding: "1vh",
                        paddingRight: "3.5vh",
                        paddingLeft: "3.5vh",
                      }}
                    >
                      Manan has been a strategy & consulting partner with Global
                      F500 companies. He has designed and implemented AI-led
                      transformation programs across Hi-Tech / CPG industries.
                    </p>
                    <div className="d-flex flex-column align-items-center">
                      <p
                        className="reduce-space"
                        style={{
                          marginTop: "-6px",
                          fontSize: "0.8em",
                          textAlign: "center",
                          marginBottom: "8px",
                        }}
                      >
                        CDO Advisory. Digital Consulting Speaker.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Lower line */}
                <div className="col-lg-4" style={{ paddingTop: "3vh" }}>
                  <div className="d-flex flex-column align-items-center top-pad">
                    <img
                      src="/assets/images/Rabindra.png"
                      className="team-img"
                      alt="pic"
                      loading="lazy"
                      style={{ width: "50%" }}
                    />
                    <div className="d-flex align-items-center">
                      <h3
                        style={{
                          fontSize: "1em",
                          textAlign: "center",
                          paddingTop: "1.4vh",
                          paddingRight: "1vh",
                        }}
                      >
                        Rabindra Neupane
                      </h3>
                      <a
                        href="https://www.linkedin.com/in/neupanerabeen/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiOutlineLinkedin color="white" fontSize={28} />
                      </a>
                    </div>
                    <h3
                      style={{
                        fontSize: "0.8em",
                        textAlign: "center",
                        paddingRight: "1vh",
                      }}
                    >
                      Founding AI Engineer
                    </h3>
                    <p
                      style={{
                        fontSize: "11px",
                        textAlign: "center",
                        fontFamily: "Carlito",
                        color: "#B9B9B9",
                        margin: "0",
                        padding: "1vh",
                        paddingRight: "3.5vh",
                        paddingLeft: "3.5vh",
                      }}
                    >
                      Rabindra is a passionate programmer with experience in
                      system design. He built Data & MLOps platform for F500
                      companies and led the Full Stack Engineering Center of
                      Excellence.
                    </p>
                    <div className="d-flex flex-column align-items-center"></div>
                  </div>
                </div>

                <div className="col-lg-4" style={{ paddingTop: "3vh" }}>
                  <div className="d-flex flex-column align-items-center top-pad">
                    <img
                      src="/assets/images/Juhel.png"
                      className="team-img"
                      alt="pic"
                      loading="lazy"
                      style={{ width: "50%" }}
                    />
                    <div className="d-flex align-items-center">
                      <h3
                        style={{
                          fontSize: "1em",
                          textAlign: "center",
                          paddingTop: "1.4vh",
                          paddingRight: "1vh",
                        }}
                      >
                        Juhel Phanju
                      </h3>
                      <a
                        href="https://www.linkedin.com/in/juhel-phanju"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiOutlineLinkedin color="white" fontSize={28} />
                      </a>
                    </div>
                    <h3 style={{ fontSize: "0.8em", textAlign: "center" }}>
                      AI Engineer. Programmer.
                    </h3>
                    <p
                      style={{
                        fontSize: "11px",
                        textAlign: "center",
                        fontFamily: "Carlito",
                        color: "#B9B9B9",
                        margin: "0",
                        padding: "1vh",
                        paddingRight: "3.5vh",
                        paddingLeft: "3.5vh",
                      }}
                    >
                      Juhel is an avid software developer with expertise in
                      developing web applications that are optimally engineered
                      for Data and AI platforms
                    </p>
                    <div className="d-flex flex-column align-items-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTeamCard;
