import React from "react";
import "./pricing.css"; // Make sure to include the CSS file for the main page
import Container from "./components/container";

// function Pricing(props) {
//   return (
//     <div
//       className="pricing-container"
//       id="pricing"
//       style={{ paddingTop: "15vh" }}
//     >
//       {/* First Container */}
//       <Container
//         heading="Individuals"
//         content="For individuals who want to ignite their analytics passion on our playground"
//         button="Free"
//         point1="5 Datasets | 100 MB Size | 100 Queries"
//         point2="Generalized LLMs"
//         point3="Limited email support"
//         point4="Cloud deployment"
//         point5="OpenAI LLMs integration"
//         point6="Limited analytical queries"
//         downButton="Sign Up"
//       />

//       {/* Second Container */}
//       <Container
//         heading="Startups & SMBs"
//         content="For growth companies looking to elevate their business analytics minds  "
//         button="Subscription"
//         point1="30 Datasets | 10 GB Size | 1K Queries"
//         point2="Domain specific fine-tuned LLMs"
//         point3="Priority support"
//         point4="On-premise  & cloud deployment"
//         point5="Open source LLMs integration"
//         point6="Wider coverage for analytical queries"
//         downButton="Talk to us"
//       />

//       {/* Third Container */}
//       <Container
//         heading="Enterprises"
//         content="For organizations aspiring to transform & scale with modern data ecosystem"
//         button="Custom"
//         point1="Unlimited Datasets | Size | Queries"
//         point2="Use-case specific fine-tuned LLMs"
//         point3="Priority 24/7 support"
//         point4="On-premise  & cloud deployment"
//         point5="Open source LLMs integration"
//         point6="Wider coverage for analytical queries"
//         downButton="Talk to us"
//       />
//     </div>
//   );
// }

function Pricing(props) {
  return (
    <div
      className="container-fluid pricing-pad"
      id="pricing"
      style={{
        paddingTop: "20vh",
        paddingRight: "15vh",
        paddingLeft: "15vh",
        marginTop: "15vh",
      }}
    >
      <div className="row">
        <div className="col-12 text-center d-lg-none d-md-none top-pad">
          <h2
            className="header"
            style={{
              fontSize: "3em",
              marginBottom: "5vh",
              textAlign: "left",
              paddingLeft: "2vh",
            }}
          >
            Pricing
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <Container
            heading="Individuals"
            content="For individuals who want to ignite their analytics passion on our playground"
            button="Free"
            point1="5 Datasets | 100 MB Size | 100 Queries"
            point2="Generalized LLMs"
            point3="Limited email support"
            point4="Cloud deployment"
            point5="OpenAI LLMs integration"
            point6="Limited analytical queries"
            downButton="Sign Up"
            link="https://ontolite-demo.azurewebsites.net/"
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <Container
            heading="Startups & SMBs"
            content="For growth companies looking to elevate their business analytics minds  "
            button="Subscription"
            point1="30 Datasets | 10 GB Size | 1K Queries"
            point2="Domain specific fine-tuned LLMs"
            point3="Priority support"
            point4="On-premise  & cloud deployment"
            point5="Open source LLMs integration"
            point6="Wider coverage for analytical queries"
            downButton="Talk to us"
            link="https://calendly.com/prinkan-pal/30min"
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <Container
            heading="Enterprises"
            content="For organizations aspiring to transform & scale with modern data ecosystem"
            button="Custom"
            point1="Unlimited Datasets | Size | Queries"
            point2="Use-case specific fine-tuned LLMs"
            point3="Priority 24/7 support"
            point4="On-premise  & cloud deployment"
            point5="Open source LLMs integration"
            point6="Wider coverage for analytical queries"
            downButton="Talk to us"
            link="https://calendly.com/prinkan-pal/30min"
          />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
