import React from "react";
import "./container.css";

function Container(props) {
  return (
    <div
      className="component rounded"
      style={{
        paddingBottom: "5vh !important",
        paddingTop: "5vh",
      }}
    >
      <h4>{props.heading}</h4>
      <p className="container">{props.content}</p>
      <button className="purple-button rounded">{props.button}</button>
      <ul className="pad-left">
        <li>{props.point1}</li>
        <li>{props.point2}</li>
        <li>{props.point3}</li>
        <li>{props.point4}</li>
        <li>{props.point5}</li>
        <li>{props.point6}</li>
      </ul>
      <a href={props.link} target="_blank" rel="noreferrer">
        <button className="grey-button rounded" style={{ marginBottom: "2vh" }}>
          {props.downButton}
        </button>
      </a>
    </div>
  );
}

export default Container;
