import React from "react";
import "./Marquee.css";
import Marquee from "react-fast-marquee";
import Microsoft from "./images/MicrosoftStartups.png";
import startupIndia from "./images/startupIndia.png";
import Nasscom from "./images/NasscomStartups.png";
import aws from "./images/aws.png";
import KarnatakaStartups from "./images/KarnatakaStartups.png";
import NSRCELMainLogo from "./images/final 2.png";
import Bizawardslogo from "./images/final.png";

const MarqueeObj = () => {
  return (
    <div
      style={{ marginTop: "15vh", paddingTop: "2vh" }}
      className="marquee-pad"
    >
      <Marquee>
        <div className="image_wrapper">
          <img src={Microsoft} alt="Microsoft" />
        </div>
        <div className="image_wrapper">
          <img src={startupIndia} alt="Startup India" />
        </div>
        <div className="image_wrapper2">
          <img src={Nasscom} alt="Nasscom" />
        </div>
        <div className="image_wrapper">
          <img src={aws} alt="aws" />
        </div>
        <div className="image_wrapper">
          <img src={NSRCELMainLogo} alt="NSRCEL" />
        </div>
        <div className="image_wrapper">
          <img src={Bizawardslogo} alt="NSRCEL" />
        </div>
        <div className="image_wrapper">
          <img src={KarnatakaStartups} alt="karnataka" />
        </div>
      </Marquee>
    </div>
  );
};

export default MarqueeObj;
