import React, { useState, useEffect } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AirplayIcon from "@mui/icons-material/Airplay";
import "./Header.css";
import Button from "react-bootstrap/Button";
// import Image from "./Picture1.png";
// import handson from "./handson.png";
import GroupsIcon from "@mui/icons-material/Groups";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CallIcon from "@mui/icons-material/Call";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import AnnouncementBar from "../Announcement/announcement";
import AodIcon from "@mui/icons-material/Aod";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

const Header = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isNewPopup, setIsNewPopup] = useState(false);
  const ANNOUNCEMENT_BAR_HEIGHT = 30; // Assuming the height of AnnouncementBar is 30px

  const [navTop, setNavTop] = useState(ANNOUNCEMENT_BAR_HEIGHT);

  const scrollToSection = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // const windowHeight = window.innerHeight;
      const headerHeight = 150;

      const sectionOffsets = {
        platform: document.getElementById("platform").offsetTop,
        approach: document.getElementById("approach").offsetTop,
        approachExt: document.getElementById("approachExt").offsetTop,
        team: document.getElementById("team").offsetTop - headerHeight,
        // resources: document.getElementById("resources").offsetTop,
        pricing: document.getElementById("pricing").offsetTop,
        contact: document.getElementById("contact").offsetTop,
      };

      let newActiveSection = null;

      const approachTop = sectionOffsets["approach"];
      const approachExtTop = sectionOffsets["approachExt"];
      // const approachRange = approachExtTop - approachTop;

      for (const sectionId in sectionOffsets) {
        const sectionTop = sectionOffsets[sectionId];
        const sectionHeight = document.getElementById(sectionId).offsetHeight;
        // const sectionBottom = sectionTop + sectionHeight;

        if (
          scrollY >= sectionTop &&
          scrollY < sectionTop + sectionHeight &&
          sectionId !== "approach"
        ) {
          newActiveSection = sectionId;
        }

        if (scrollY >= approachTop && scrollY <= approachExtTop) {
          newActiveSection = "approach";
        }
      }

      const visibleHeight = Math.max(ANNOUNCEMENT_BAR_HEIGHT - scrollY, 0);
      setNavTop(visibleHeight);

      setActiveSection(newActiveSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AnnouncementBar />
      <nav
        style={{
          background: "#000",
          color: "#ccc",
          position: "fixed",
          top: `${navTop}px`,
          zIndex: 99999,
          padding: "1em",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-6 col-6">
              <div className="nav-logo-container">
                <a href="#banner" className="page-scroll dropdown">
                  <img
                    src="/assets/Icons/Picture1.png"
                    alt="LegoAI"
                    className="logo1"
                    style={{ minWidth: "80px" }}
                  />
                </a>
              </div>
            </div>

            <div className="col-lg-8 col-md-7  d-none d-md-block ">
              <div
                className="navbar-links-container"
                style={{ height: "100%" }}
              >
                <ul
                  className="nav-items"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    maxWidth: "700px",
                    minWidth: "400px",
                    margin: "0px auto",
                  }}
                >
                  <li className="nav-item ">
                    <a
                      href="#platform"
                      className={`page-scroll dropdown  ${
                        activeSection === "platform" ? "active" : ""
                      }`}
                      style={{ color: "#B9B9B9" }}
                    >
                      Platform
                    </a>
                  </li>
                  <li className={`nav-item`}>
                    <a
                      href="#approach"
                      className={`page-scroll dropdown  ${
                        activeSection === "approach" ||
                        activeSection === "approachExt"
                          ? "active"
                          : ""
                      }`}
                      style={{ color: "#B9B9B9 " }}
                    >
                      Approach
                    </a>
                  </li>
                  <li className={`nav-item`}>
                    <a
                      href="#team"
                      className={`page-scroll dropdown  ${
                        activeSection === "team" ? "active" : ""
                      }`}
                      style={{ color: "#B9B9B9 " }}
                    >
                      Team
                    </a>
                  </li>
                  <li className={`nav-item`}>
                    <a
                      href="#resources"
                      className={`page-scroll dropdown  ${
                        activeSection === "resources" ? "active" : ""
                      }`}
                      style={{ color: "#B9B9B9 " }}
                    >
                      Resources
                    </a>
                  </li>
                  <li className={`nav-item`}>
                    <a
                      href="#pricing"
                      className={`page-scroll dropdown  ${
                        activeSection === "pricing" ? "active" : ""
                      }`}
                      style={{ color: "#B9B9B9 " }}
                    >
                      Pricing
                    </a>
                  </li>
                  <li className={`nav-item`}>
                    <a
                      href="#contact"
                      className={`page-scroll dropdown  ${
                        activeSection === "contact" ? "active" : ""
                      }`}
                      style={{ color: "#B9B9B9 " }}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 col-4">
              <GetOntoUs
                isNewPopup={isNewPopup}
                setIsNewPopup={setIsNewPopup}
              />
            </div>

            <div
              id="menuwrapper"
              className="col-lg-1 col-md-1 col-sm-2 col-2 d-xl-none d-lg-none d-md-none text-right"
              style={{
                fontSize: "1.2em",
              }}
              onClick={() => setOpenMenu(true)}
            >
              <HiOutlineBars3 />
            </div>
          </div>
        </div>

        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
          style={{
            zIndex: 99999999,
          }}
          PaperProps={{
            sx: {
              background: "#333",
              color: "#fff",
              // boxShadow: "0px 0px 15px #333",
            },
            elevation: 5,
          }}
        >
          <Box sx={{ width: 250 }} role="presentation">
            <List>
              <ListItemButton onClick={() => scrollToSection("platform")}>
                <ListItemIcon>
                  <AirplayIcon style={styles.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Platform" />
              </ListItemButton>

              <ListItemButton onClick={() => scrollToSection("approach")}>
                <ListItemIcon>
                  <WbIncandescentIcon style={styles.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Approach" />
              </ListItemButton>

              <ListItemButton onClick={() => scrollToSection("team")}>
                <ListItemIcon>
                  <GroupsIcon style={styles.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Team" />
              </ListItemButton>

              <ListItemButton onClick={() => scrollToSection("resources")}>
                <ListItemIcon>
                  <AodIcon style={styles.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Resources" />
              </ListItemButton>

              <ListItemButton onClick={() => scrollToSection("pricing")}>
                <ListItemIcon>
                  <AttachMoneyIcon style={styles.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Pricing" />
              </ListItemButton>

              <ListItemButton onClick={() => scrollToSection("contact")}>
                <ListItemIcon>
                  <CallIcon style={styles.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
      </nav>
    </>
  );
};

const GetOntoUs = (props) => {
  return (
    <Button
      onClick={() => {
        props.setIsNewPopup(!props.isNewPopup);
      }}
      href="https://ontolite-demo.azurewebsites.net/"
      className="wait-list-btn"
      style={{
        float: "right",
        padding: "0",
        background: "transparent",
        border: "none",
        outline: "white",
        marginRight: "10%",
        maxWidth: "170px",
        backgroundColor: "#bf21ff",
        fontSize: "2rem", // Set the default font size
        minWidth: "75px",
        marginLeft: "1vh",
      }}
      target="_blank"
    >
      <div
        style={{
          padding: "1vh",
          paddingLeft: "1vh",
          paddingRight: "1vh",
          fontWeight: "bolder",
          fontSize: "1.4vh",
          minWidth: "95px",
          paddingTop: "1.2vh",
          paddingBottom: "1.2vh",
          fontFamily: "Open Sans, sans-serif",
        }}
        className="try-us-now"
      >
        Try For Free
      </div>
    </Button>
  );
};

const styles = {
  iconColor: {
    color: "#fee",
  },
};
export default Header;
