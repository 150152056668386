import React from "react";
import "./approach.css"; // Import a CSS file for styling

const Approach = (props) => {
  return (
    <div
      id="approach"
      style={{
        scrollSnapType: "y proximity",
        marginTop: "13vh",
        paddingTop: "12vh",
        paddingLeft: "15vh",
        paddingRight: "15vh",
      }}
      className="container-fluid text-center approach-pad"
    >
      <div className="row justify-content-center">
        <div className="col-lg-9 col-12 col-sm-12 ">
          <h2 className="header" style={{ fontSize: "2.5em" }}>
            Our Approach Towards Building
            <span className="themed_color"> GenAI</span> Led Modern Data &
            Analytics Ecosystem
          </h2>
        </div>
      </div>

      <div className="row gridContainer">
        <div
          className="col-lg-4 col-md-4 col-sm-12 col-12"
          style={{ padding: "0" }}
        >
          <div className="approach-item">
            <ApproachComponent2
              src={process.env.PUBLIC_URL + "/assets/images/key-pillar.png"}
              title="Key Pillars of Data Management"
            />
          </div>
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12 col-12"
          style={{ padding: "0" }}
        >
          <div className="approach-item">
            <ApproachComponent
              src={process.env.PUBLIC_URL + "/assets/images/Pic3.png"}
              title="Embedded with Intelligence"
            />
          </div>
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12 col-12"
          style={{ padding: "0" }}
        >
          <div className="approach-item">
            <ApproachComponent1
              src={process.env.PUBLIC_URL + "/assets/images/Dataeco.png"}
              title="Modern Data & Analytics Ecosystem"
              style={{}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;

const ApproachComponent = (props) => {
  return (
    <div className="d-flex flex-column justify-content-between">
      <div>
        <div className="approachComponent">
          <h3 className="title">{props.title || ""}</h3>
        </div>

        <hr
          style={{
            width: "50%",
            margin: "0 auto",
            marginTop: "-35px",
            borderTop: "2px solid #7c7c7c",
            marginBottom: "10px",
          }}
        />
      </div>
      <div style={{ paddingTop: "75%", position: "relative" }}>
        <img
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          src={props.src || ""}
          alt="logo"
        />
      </div>
    </div>
  );
};

const ApproachComponent1 = (props) => {
  return (
    <div className="d-flex flex-column justify-content-between left">
      <div>
        <div className="approachComponent">
          <h3 className="title">{props.title || ""}</h3>
        </div>

        <hr
          style={{
            width: "50%",
            margin: "0 auto",
            marginTop: "-35px",
            borderTop: "2px solid #7c7c7c",
            marginBottom: "10px",
          }}
        />
      </div>
      <div style={{ paddingTop: "75%", position: "relative" }}>
        <img
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            paddingRight: "8vh",
          }}
          src={props.src || ""}
          alt="logo"
        />
      </div>
    </div>
  );
};

const ApproachComponent2 = (props) => {
  return (
    <div className="d-flex flex-column justify-content-between">
      <div>
        <div className="approachComponent">
          <h3 className="title">{props.title || ""}</h3>
        </div>

        <hr
          style={{
            width: "50%",
            margin: "0 auto",
            marginTop: "-35px",
            borderTop: "2px solid #7c7c7c",
            marginBottom: "10px",
          }}
        />
      </div>
      <div style={{ paddingTop: "75%", position: "relative" }}>
        <img
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "98%",
            objectFit: "contain",
          }}
          src={props.src || ""}
          alt="logo"
        />
      </div>
    </div>
  );
};
