/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./Contact.css";
//import Con from "./contact-png.png";
import emailjs from "@emailjs/browser";
import { Alert } from "react-bootstrap";
// import { BsTwitter } from "react-icons/bs";
// import { BsYoutube } from "react-icons/bs";
// import { AiOutlineLinkedin } from "react-icons/ai";

import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";

import * as Yup from "yup";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import call from "./images/call.png";
import message from "./images/message.png";
import location from "./images/location.png";
import linkedin from "./images/Linkedin.png";
import youtube from "./images/Youtube.png";

// function adjustMargin() {
//   const copyright = document.getElementById("copyright");
//   if (copyright === null) return;
//   if (window.innerWidth <= 767) {
//     copyright.style.marginBottom = "0";
//   } else {
//     // copyright.style.marginBottom = "15vh";
//   }
// }

// // Initial adjustment on page load
// window.addEventListener("load", adjustMargin);

// // Adjust margin on window resize
// window.addEventListener("resize", adjustMargin);

export default function Contact() {
  const [showAlert, setShowAlert] = useState(false);
  // const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }, [showAlert]);

  const [buttonLoading, setButtonLoading] = useState(false);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };

  // const validateForm = () => {
  //   const errors = {};
  //   let isValid = true;

  //   // Validate name field
  //   if (!form.current.name.value.trim()) {
  //     errors.name = "Please enter your name";
  //     isValid = false;
  //   }
  //   // Validate country field
  //   if (!form.current.country.value.trim()) {
  //     errors.country = "Please enter Country";
  //     isValid = false;
  //   }
  //   //phone number

  //   {
  //     /*if (!form.current.phone.value.trim()) {
  //     errors.phone = "Please enter your phone Number";
  //     isValid = false;
  //   } else if (
  //     /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
  //       form.current.phone.value
  //     )
  //   ) {
  //     errors.phone = "Please enter a valid phone Number";
  //     isValid = false;
  //   }
  // */
  //   }
  //   // Validate email field
  //   if (!form.current.email.value.trim()) {
  //     errors.email = "Please enter your email address";
  //     isValid = false;
  //   } else if (!/\S+@\S+\.\S+/.test(form.current.email.value)) {
  //     errors.email = "Please enter a valid email address";
  //     isValid = false;
  //   }

  //   // Validate message field
  //   if (!form.current.message.value.trim()) {
  //     errors.message = "Please enter a message";
  //     isValid = false;
  //   }

  //   setFormErrors(errors);

  //   return isValid;
  // };
  const products = [
    { id: 1, name: "OntoLite", link: "#OntoCraft" },
    { id: 2, name: "OntoCraft", link: "#OntoSphere" },
    { id: 3, name: "OntoSphere", link: "#OntoQl" },
  ];
  const links = [
    { id: 1, text: "API Docs", url: "http://docs.legoai.com/" },
    { id: 2, text: "Blogs", url: "http://blogs.legoai.com/" },
    { id: 3, text: "Sign Up", url: "https://ontolite-demo.azurewebsites.net/" },
    // { id: 3, text: "Services", url: "#" },
    // { id: 4, text: "Feedback", url: "#" },
  ];

  // const phoneRegExp =
    // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const EmailRegExp =
    /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;
  const NameRegExp = /^[A-Za-z\s]{1,}[.]{0,1}[A-Za-z\s]{0,}$/;
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    //we have created our initailValues object in a format EmailJS accepts
    initialValues: {
      name: "", //user name
      email: "", //email id of the admin
      company: "", // Company of email
      phone: "", // user email
      message: "", // message of email
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(NameRegExp, "Please Enter valid Name")
        .required("* Name field is required"),
      company: Yup.string().required("* Company field is required"),
      phone: Yup.string().max(18, "too long"),
      email: Yup.string()
        .matches(EmailRegExp, "Please Enter Bussiness Email")
        .email("Invalid email address")
        .required("* Email field is required"),
      message: Yup.string().max(500, "too long"),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      emailjs
        .send(
          "contactuslegoai",
          "template_vgas58t",
          values,
          "zDx9DH3eMBukfPCv_"
        )

        .then(
          (result) => {
            // setAlertType("success");
            setAlertMessage("Message sent successfully!");
            setButtonLoading(false);
            setShowAlert(true);
            resetForm();
          },
          (error) => {
            // setAlertType("danger");
            setAlertMessage(
              "An error occurred while sending your message. Please try again later."
            );
            setShowAlert(true);
          }
        );
    },
  });
  return (
    <>
      <div
        id="contact"
        className="container-fluid newContact contact-pad"
        style={{
          marginBottom: "4px",
          paddingTop: "17vh",
          paddingRight: "15vh",
          paddingLeft: "15vh",
          paddingBottom: "4vh",
          marginTop: "8vh",
        }}
      >
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
            <h2 style={{ marginbottom: "25px", marginLeft: "16px" }}>
              Contact Us
            </h2>
            {showAlert && (
              <div
                style={{ position: "relative", height: "150px", width: "100%" }}
              >
                <Alert
                  variant="success"
                  onClose={() => setShowAlert(false)}
                  dismissible
                  style={{ position: "absolute", top: "172px", right: "10px" }}
                >
                  {alertMessage}
                </Alert>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="contact-info" style={{ marginLeft: "16px" }}>
              <div className="contact-details" style={{ marginTop: 0 }}>
                <span>
                  <img
                    src={call}
                    alt="phoneImage"
                    style={{
                      height: "40px",
                      minWidth: "40px",
                      objectFit: "contain",
                    }}
                  />
                </span>
                <div>
                  <span style={{ color: "#B9B9B9" }}>Phone No.</span>
                  <span style={{ color: "#B9B9B9" }} className="text">
                    +91-8884321107
                  </span>
                </div>
              </div>
              <div className="contact-details">
                <span>
                  <img
                    src={message}
                    alt="phoneImage"
                    style={{
                      height: "40px",
                      minWidth: "40px",
                      objectFit: "contain",
                    }}
                  />
                </span>
                <div>
                  <span style={{ color: "#B9B9B9" }}>E-mail</span>
                  <span style={{ color: "#B9B9B9" }} className="text">
                    contactus@legoai.com
                  </span>
                </div>
              </div>
              <div className="contact-details">
                <span>
                  <img
                    src={location}
                    alt="phoneImage"
                    style={{
                      height: "40px",
                      minWidth: "40px",
                      objectFit: "contain",
                    }}
                  />
                </span>
                <div>
                  <span style={{ color: "#B9B9B9" }}>Address</span>
                  <span style={{ color: "#B9B9B9" }} className="text">
                    91 SpringBoard, 512/10, Outer Ring Rd <br />
                    Mahadevapura, Bengaluru, India, 560048{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div id="Contact" className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div
              style={{
                marginLeft: window.innerWidth <= 767 ? "16px" : "0",
                marginRight: window.innerWidth <= 767 ? "px" : "0",
              }}
            >
              <form
                className="formcontact formcontact-footer"
                onSubmit={handleSubmit}
              >
                <div className="row g-3">
                  <div className="col-sm-6">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Name"
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? (
                      <p className="form-error">{errors.name}</p>
                    ) : null}
                  </div>
                  <div className="col-sm-6">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Bussiness Email"
                      autoComplete="off"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <div className="form-error">{errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <input
                      id="company"
                      name="company"
                      type="text"
                      autoComplete="off"
                      placeholder="Company"
                      className="form-control"
                      onChange={handleChange}
                      value={values.company}
                      onBlur={handleBlur}
                    />
                    {/*<Select
                  name="country"
                  placeholder="Country"
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  isSearchable={true}
                  value={values.selectedOption}
                  options={Countrydata}
                  onChange={(selectedOption) =>
                    setFieldValue("country", selectedOption.value)
                  }
                  />
                */}
                    {touched.company && errors.company ? (
                      <p className="form-error">{errors.company}</p>
                    ) : null}
                  </div>
                  <div className="col-sm-6">
                    <PhoneInput
                      id="phone"
                      type="phone"
                      name="phone"
                      country={"in"}
                      value={values.phone}
                      onChange={(value) => {
                        setFieldValue("phone", value);
                      }}
                      inputClass="form-control"
                      inputProps={{
                        name: "phone",
                        required: true,
                        // autoFocus: true,
                      }}
                    />

                    {touched.phone && errors.phone ? (
                      <p className="form-error">{errors.phone}</p>
                    ) : null}
                  </div>
                </div>

                <textarea
                  rows="4"
                  id="message"
                  name="message"
                  placeholder="Message"
                  autoComplete="off"
                  className="form-control"
                  onChange={handleChange}
                  value={values.message}
                  onBlur={handleBlur}
                />
                {touched.message && errors.message ? (
                  <p className="form-error">{errors.message}</p>
                ) : null}
                <input
                  type="submit"
                  disabled={buttonLoading && true}
                  className="send-btn"
                  value={buttonLoading ? "SUBMITING" : "SUBMIT"}
                />
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            style={{
              paddingTop: "3%",
              paddingBottom: "0",
              display: "flex",
              flexDirection: "row",
            }}
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 responsive-row"
          >
            <div
              style={{
                marginBottom: "1.5%",
                marginLeft: "16px",
                paddingTop: "1vh",
                marginRight: "5vh",
              }}
            >
              <a href="#banner">
                <img
                  style={{ height: "44px" }}
                  src="/assets/Icons//Picture1.png"
                  alt="LegoAI Company"
                />
              </a>
              <p>Simplifying AI for Enterprises</p>
            </div>
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                style={{ marginLeft: "2vh", paddingBottom: "2vh" }}
              >
                <h2
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "28px",
                  }}
                >
                  Follow us on
                </h2>

                {/* <a style={{ margin: "0 8px 0 0" }} href="#" target="_blank">
                  <img
                    src="https://legoai.com/assets/images/Twitter.png"
                    alt="twitterlogo"
                    style={{ height: "28px" }}
                  />
                </a> */}
                <a
                  style={{ margin: "0 8px" }}
                  href="https://www.linkedin.com/company/legoai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedin}
                    alt="Linkedin"
                    style={{ height: "28px" }}
                  />
                </a>
                <a
                  style={{ margin: "0 8px" }}
                  href="https://www.youtube.com/@LEGOAI/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={youtube}
                    alt="twitterlogo"
                    style={{ height: "28px" }}
                  />
                </a>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingTop: "2%",
              paddingBottom: "0",
              justifySelf: "flex-end",
              marginLeft: window.innerWidth <= 767 ? "16px" : "0",
            }}
            className="col-lg-3 col-md-3 col-sm-12 col-xs-12"
          >
            <h2 style={{ fontSize: "20px", color: "#B9B9B9" }}>
              Our Offerings
            </h2>
            <ul className="left-pad">
              {products.map((item) => (
                <li key={item.id}>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "white",
                      paddingLeft: "1rem !important",
                    }}
                    href={item.link}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div
            style={{
              paddingTop: "2%",
              paddingBottom: "0",
              justifySelf: "flex-end",
              marginLeft: window.innerWidth <= 767 ? "16px" : "0",
            }}
            className="col-lg-3 col-md-3 col-sm-12 col-xs-12"
          >
            <h2 style={{ fontSize: "20px", color: "#B9B9B9" }}>Useful links</h2>
            <ul className="left-pad">
              {links.map((link) => (
                <li key={link.id}>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          style={{ justifySelf: "flex-end", marginLeft: "16px" }}
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        >
          <div>
            <p
              id="copyright"
              style={{
                textAlign: "center",
                marginBottom: "0vh",
                alignItems: "center",
                justifyItems: "center",
                color: "#B9B9B9 ",
                paddingTop: "8vh",
              }}
            >
              Copyright © {new Date().getFullYear()} All rights reserved by
              LEGOAI TECHNOLOGIES
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
