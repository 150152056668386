import React from "react";
import { Container } from "react-bootstrap";
import "./platform.css";
import OntoLite from "./OntoLite_Logo.png";

const Platform = () => {
  return (
    <Container id="platform">
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "15vh",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          paddingBottom: "3vh",
        }}
        className="onto-logo"
      >
        <h2 style={{ width: "90%" }}>
          <img
            src={OntoLite}
            alt="Ontolite"
            loading="lazy"
            style={{
              width: "auto",
              objectFit: "contain",
              paddingTop: "1vh",
              maxHeight: "14vh",
              minHeight: "8vh",
              marginRight: "-22px",
            }}
            className="onto-lite"
          />
          : AI Augmented Insights Generation Studio
        </h2>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "3vh" }}
      >
        <div className="ratio ratio-16x9" style={{ maxWidth: "80%" }}>
          <iframe
            src="https://www.youtube.com/embed/F6gIz3gT3M8?si=wKm1dvJiLjL3DtoS?modestbranding=1&amp;&showsearch=0&iv_load_policy=3&rel=0&controls=0&showinfo=1 "
            title="YouTube video"
            allowFullScreen
            className="embed-responsive-item"
            style={{ width: "100%", boxShadow: "0px 0px 30px #555" }}
          ></iframe>
        </div>
      </div>
    </Container>
  );
};

// <iframe width="560" height="315" src="https://www.youtube.com/embed/F6gIz3gT3M8?si=wKm1dvJiLjL3DtoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

export default Platform;
